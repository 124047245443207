require("jquery");

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "slick-carousel/slick/slick";
import "channels";
import "./dropdown";
import "./toggle";

Rails.start();
Turbolinks.start();
ActiveStorage.start();

// Stylesheets
import "../stylesheets/application";

$(document).on("turbolinks:load", function () {
  $(".background-image-holder").each(function () {
    var imgSrc = $(this).children("img").attr("src");
    $(this)
      .css("background", 'url("' + imgSrc + '")')
      .css("background-position", "initial")
      .css("opacity", "1");
  });

  $(".gallery").slick({
    adaptiveHeight: true,
    arrows: true,
  });
  
  $(".photo-gallery").slick({
    adaptiveHeight: true,
    arrows: true,
    fade: true,
    cssEase: 'linear'
  });
});

require("trix");
require("@rails/actiontext");
