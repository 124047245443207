$(document).on("turbolinks:load", function () {
  var done;
  var rtl = false;

  if ($('html[dir="rtl"]').length) {
    rtl = true;
  }

  if (!done) {
    $(document).on(
      "click",
      "body:not(.dropdowns--hover) .dropdown, body.dropdowns--hover .dropdown.dropdown--click",
      function (event) {
        var dropdown = $(this);
        if ($(event.target).is(".dropdown--active > .dropdown__trigger")) {
          dropdown
            .siblings()
            .removeClass("dropdown--active")
            .find(".dropdown")
            .removeClass("dropdown--active");
          dropdown.toggleClass("dropdown--active");
        } else {
          $(".dropdown--active").removeClass("dropdown--active");
          dropdown.addClass("dropdown--active");
        }
      }
    );
    $(document).on(
      "click touchstart",
      "body:not(.dropdowns--hover)",
      function (event) {
        if (!$(event.target).is('[class*="dropdown"], [class*="dropdown"] *')) {
          $(".dropdown--active").removeClass("dropdown--active");
        }
      }
    );
    $("body.dropdowns--hover .dropdown").on("click", function (event) {
      event.stopPropagation();
      var hoverDropdown = $(this);
      hoverDropdown.toggleClass("dropdown--active");
    });

    // Append a container to the body for measuring purposes
    $("body").append(
      '<div class="container containerMeasure" style="opacity:0;pointer-events:none;"></div>'
    );

    // Menu dropdown positioning
    if (rtl === false) {
      repositionDropdowns();
      $(window).on("resize", function () {
        repositionDropdowns();
      });
    } else {
      repositionDropdownsRtl();
      $(window).on("resize", function () {
        repositionDropdownsRtl();
      });
    }

    done = true;
  }

  function repositionDropdowns() {
    $(".dropdown__container").each(function () {
      var container, containerOffset, masterOffset, menuItem, content;

      $(this).css("left", "");

      container = $(this);
      containerOffset = container.offset().left;
      masterOffset = $(".containerMeasure").offset().left;
      menuItem = container.closest(".dropdown").offset().left;
      content = null;

      container.css("left", -containerOffset + masterOffset);

      if (container.find('.dropdown__content:not([class*="lg-12"])').length) {
        content = container.find(".dropdown__content");
        content.css("left", menuItem - masterOffset);
      }
    });
    $(".dropdown__content").each(function () {
      var dropdown, offset, width, offsetRight, winWidth, leftCorrect;

      dropdown = $(this);
      offset = dropdown.offset().left;
      width = dropdown.outerWidth(true);
      offsetRight = offset + width;
      winWidth = $(window).outerWidth(true);
      leftCorrect = $(".containerMeasure").outerWidth() - width;

      if (offsetRight > winWidth) {
        dropdown.css("left", leftCorrect);
      }
    });
  }

  function repositionDropdownsRtl() {
    var windowWidth = $(window).width();

    $(".dropdown__container").each(function () {
      var container, containerOffset, masterOffset, menuItem, content;

      $(this).css("left", "");

      container = $(this);
      containerOffset =
        windowWidth - (container.offset().left + container.outerWidth(true));
      masterOffset = $(".containerMeasure").offset().left;
      menuItem =
        windowWidth -
        (container.closest(".dropdown").offset().left +
          container.closest(".dropdown").outerWidth(true));
      content = null;

      container.css("right", -containerOffset + masterOffset);

      if (container.find('.dropdown__content:not([class*="lg-12"])').length) {
        content = container.find(".dropdown__content");
        content.css("right", menuItem - masterOffset);
      }
    });
    $(".dropdown__content").each(function () {
      var dropdown, offset, width, offsetRight, winWidth, rightCorrect;

      dropdown = $(this);
      offset =
        windowWidth - (dropdown.offset().left + dropdown.outerWidth(true));
      width = dropdown.outerWidth(true);
      offsetRight = offset + width;
      winWidth = $(window).outerWidth(true);
      rightCorrect = $(".containerMeasure").outerWidth() - width;

      if (offsetRight > winWidth) {
        dropdown.css("right", rightCorrect);
      }
    });
  }
});
